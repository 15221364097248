@import '~primeng/resources/primeng.min.css';
@import '~primeng/resources/themes/saga-orange/theme.css';
@import '~primeicons/primeicons.css';
@import '~primeflex/primeflex.css';

html,
body {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.p-card {
	margin: 0.25em;
}

.p-card .p-card-body {
	border-style: solid;
	border-width: 1px;
	border-color: darkgray;
	border-radius: 3px;
}

.iconBar {
	border: 1pt;
	border-color: darkgray;
	border-style: solid;
	border-radius: 2pt;
}

.p-tree {
	border-style: solid;
	border-width: 1px;
	border-color: darkgray;
	border-radius: 3px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
	background: #fff7de;
}

.p-datatable .p-datatable-tbody > tr {
	background: #ffffff;
}
